.project-buttons {
    position: absolute;
    display: block;
	top: 50%;
    z-index: 100;
}

.description {
    position: absolute;
    left: 75%;
    top:25%;
    z-index: 100;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 300%;
    color: white;
}

.Javamon .MarioLuigi .Pokecrosscss .TheBigBotcss .Thissite .Lululemon .button {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 100%;
    height: 20px;
    width: 20px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-color: #04AA6D;
}